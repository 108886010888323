<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img
          class="navbar-brand-logo"
          src="/assets/images/djsaturn-logo.svg"
          alt="DJ Saturn"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="/#videoMixes"
              >Mixes</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <main>
    <router-view />
  </main>
  <footer class="mt-auto">
    <div class="container pb-3">
      <hr />
      <div
        class="row align-items-center justify-content-between flex-column flex-sm-row"
      >
        <div class="col-auto">
          <div class="small m-0 text-white">Copyright © djsaturn.com {{ new Date().getFullYear() }}</div>
        </div>
        <div class="col-auto">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
                class="btn btn-xs btn-light"
                href="https://www.instagram.com/djs4turn"
                target="_blank"
              >
                <i class="bi bi-instagram"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="btn btn-xs btn-light"
                href="https://twitter.com/djsaturn"
                target="_blank"
              >
                <i class="bi bi-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="btn btn-xs btn-light"
                href="https://www.youtube.com/djs4turn"
                target="_blank"
              >
                <i class="bi bi-youtube"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="btn btn-xs btn-light"
                href="https://www.twitch.tv/djsaturn_"
                target="_blank"
              >
                <i class="bi bi-twitch"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
.navbar-brand-logo {
  width: 100%;
  min-width: 10rem;
  max-width: 10rem;
}
</style>
